import React, { Component } from 'react';
import '../style.css';
import email from '../../../Assets/img/email.svg'
import  '../responsive.css';

export default class Mav extends Component {
  render() {
    return <div className="mav_wrapper">
            <div className="mav_info_wrapper"> 
            <div className="left">
            <img src={email} alt="img"/>
            </div>
            <div className="right">
            <div className="info_right_wrapper_box">

                <div className="box_info">
                        <h2>Pesquisa e desenvolvimento</h2>
                        Somos uma empresa genuinamente brasileira, que dedica 20 mil
                        horas por ano na constante melhoria de nossos produtos
                        e no desenvolvimento de novas soluções.
                </div>

                <div className="box_info">
                        <h2>Escalabilidade dos produtos</h2>
                        Ferramentas múltiplas e completas compõem nosso portfólio
                            de produtos e atendem integralmente nossos clientes
                            à medida em que novas necessidades vão surgindo.
                </div>

                <div className="box_info">
                        <h2>Melhor relação custo x benefício</h2>
                        Estamos na vanguarda da inovação. E como desenvolvemos
                            nossos próprios produtos, oferecemos as melhores
                            soluções com os preços mais competitivos do mercado.
                </div>
                
                <div className="box_info">
                        <h2>Atendimento técnico personalizado</h2>
                        Nossa equipe interna e nossos canais de comunicação
                            (telefone, e-mail, WhatsApp, Skype e mídias sociais) 
                            estão à disposição dos clientes, para auxiliá-los em 
                            todas as suas demandas.
                </div>
            </div>
            </div>
            </div>
    </div>;
  }
}
