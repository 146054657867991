import React, { Component } from 'react';
import antispam from '../../../Assets/img/anti.webp';
import arquivamento from '../../../Assets/img/arquivamento.webp';
import controle from '../../../Assets/img/controle.webp';
import backup from '../../../Assets/img/backup.webp';
import recibo from '../../../Assets/img/recibo_grid.webp';

import  './style.css';
import  './responsive.css';
export default class Propriedades extends Component {
  render() {
    return (
    <div className="propriedades_wrapper">
        <div className="title">
            <h1>funcionalidades</h1>
            <span/>
            {/* <p><strong>See plan details and pricing</strong></p> */}
        </div>
        <div className="boxes_props_wrapper">
            <div className="left">
                <div className="content_props">
                    <h1>Antispam</h1>
                    <p>Garanta 99,9% de eficácia na proteção contra spams,
                    fraudes e phishing (roubo de informações)
                    que chegam por e-mail diariamente.
                    Para isso, são feitas análises de textos do e-mail,
                    black list e assinaturas geradas automaticamente.
                    O bloqueio desses spams além de garantir a segurança
                    também aumenta a produtividade, evitando perda de tempo
                    com e-mails que não são o foco do seu negócio.</p>
                </div>
            </div>
            <div className="right">
                <img src={antispam} alt="antispam"/>
            </div>
        </div>


        <div className="boxes_props_wrapper reverse ">
            <div className="left">
                <div className="content_props">
                    <h1>Arquivamento</h1>
                    <p>Acesse o registro de todo o tráfego e conteúdo dos seus e-mails.
                         Além do melhor monitoramento dos dados, é possível recuperar
                          mensagens já excluídas pelos usuários e fazer o arquivamento,
                           ou seja, a documentação dessas informações.</p>
                </div>
            </div>
            <div className="right">
            <img src={arquivamento} width="80%" alt="antispam"/>
            </div>
        </div>



        <div className="boxes_props_wrapper">
            <div className="left">
                <div className="content_props">
                    <h1>Controle De Acesso</h1>
                    <p>Evite acessos inesperados que podem gerar horas extras
                         de seus colaboradores ou aumentar o risco de vazamentos
                          de informações confidenciais. Basta bloquear o
                           acesso aos e-mails fora do horário comercial ou da rede da empresa.</p>
                </div>
            </div>
            <div className="right">
            <img src={controle} width="70%" alt="antispam"/>
            
            </div>
        </div>




        <div className="boxes_props_wrapper reverse">
            <div className="left"> 
                <div className="content_props">
                    <h1>Backup</h1>
                        <p>Tenha cópias de segurança de todos os e-mails que circulam
                        na empresa e recupere qualquer mensagem, de maneira
                        imediata. Esses e-mails não podem ser alterados ou
                        apagados e ficam armazenados até 10 anos.</p>
                </div>
            </div>
            <div className="right">
            <img src={backup} width="80%" alt="antispam"/>
            </div>
        </div>


        
        <div className="boxes_props_wrapper">
            <div className="left">
                <div className="content_props">
                    <h1>Recibo De Entrega</h1>
                        <p>Identifique problemas no envio de e-mails
                        e evite falhas na comunicação com seu cliente.
                        Disponibilizamos relatórios para que você cheque
                        se um e-mail saiu da sua caixa de saída ou parou
                        no servidor do destinatário.</p>
                </div>
            </div>
            <div className="right">
            <img src={recibo} width="100%" alt="antispam"/>
            </div>
        </div>
        <div className="text_button">Converse com o nosso <strong>Gerente de Contas</strong></div>

<div className="button">Clique aqui</div>
    </div>);
  }
}
