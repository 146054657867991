import React, { Component } from 'react';
import './style.css';
import  './responsive.css';
import Propriedades from './Propriedades';
import Mav from './Mav';
export default class Home extends Component {
  render() {
    return (
        <div className="home_wrapper">
            <div className="banner"/>
            <Mav/>
            <Propriedades/>
        </div>
    );
  }
}
