import React from 'react';
import Home from './Pages/Home';
import Footer from './Components/Footer';
// import {BrowserRouter} from 'react-router-dom'
// import Routes from './Routes';

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter>
          <Routes/>
      </BrowserRouter> */}
      <Home/>
      
      <Footer/>
    
    </div>
  );
}

export default App;
